import React, { useState, useEffect } from 'react'
import axios from 'axios'
// import Records from "./Records"
import Pagination from './Pagination';

function App() {

    // To hold the actual data
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);


    useEffect(() => {
        axios.get('/test-data')
            .then(res => {
              console.log()
                    setData(res.data);
                    setLoading(false);
                })
                .catch(() => {
                    alert('There was an error while retrieving the data')
                })
    }, [])

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(data.length / recordsPerPage)

    return (
        <div className='container mt-5'>
            <h2> Simple Pagination Example in React </h2>
            {/* <Records data={currentRecords}/> */}
            <table className="table">
              <thead>
                  <tr>
                      <th scope='col'>ID</th>
                      <th scope='col'>First Name</th>
                      <th scope='col'>Last Name</th>
                      <th scope='col'>City</th>

                  </tr>
              </thead>
              <tbody>
                  {currentRecords.map(item => (
                      <tr>
                          <td>{item.id} </td>
                          <td>{item.first_name} </td>
                          <td>{item.last_name} </td>
                          <td>{item.city} </td>
                      </tr>
                  ))}
              </tbody>
            </table>
            <Pagination
                nPages={nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            {/* <div class="themetechmount-pagination">
        
                <a class="prev page-numbers" href="#"><i class="tm-acadevo-icon-arrow-left"></i> 
                  <span class="tm-hide tm-pagination-text tm-pagination-text-prev">Previous page</span>
                </a>
                <span aria-current="page" class="page-numbers current">1</span>
                <a class="page-numbers" href="https://www.bcacollegesinbangalore.com/blogs?page=2">2</a>
                <a class="next page-numbers" href="https://www.bcacollegesinbangalore.com/blogs?page=2">
                  <span class="tm-hide tm-pagination-text tm-pagination-text-next">Next page</span> <i class="tm-acadevo-icon-arrow-right"></i>
                </a>

                <div class="themetechmount-pagination">
                
                </div>
            </div> */}
        </div>
    );
}

export default App;